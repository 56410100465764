import "./App.css";
import { Routes, Route, Navigate } from "react-router-dom";
import { Helmet } from "react-helmet";
import Menu from "./Menu";
import Bilder from "./views/bilder";
// import Home from "./views/home";
// import Helgen from "./views/helgen";
// import HittaHit from "./views/hitta-hit";
// import Kladsel from "./views/kladsel";
// import Kontakt from "./views/kontakt";
// import Osa from "./views/osa";
// import Gaster from "./views/gaster";

function App() {
  return (
    <div className="App">
      <Helmet
        titleTemplate="%s - David & Astrid"
        defaultTitle="David & Astrid"
      ></Helmet>
      <Menu>
        <>
          <Routes>
            <Route path="/" element={<Bilder />} />
            <Route
              path="*"
              element={
                <>
                  <h1>Hittar inte sidan</h1>
                  <Navigate replace to="/" />
                </>
              }
            />
          </Routes>
        </>
      </Menu>
    </div>
  );
}

export default App;
