import { Typography } from "@mui/material";

export const H1 = (props) => {
  return <Heading {...props} variant="h1" />;
};
export const H2 = (props) => {
  return <Heading {...props} variant="h2" />;
};
export const H3 = (props) => {
  return <Heading {...props} variant="h3" />;
};
export const H4 = (props) => {
  return <Heading {...props} variant="h4" />;
};

function Heading(props) {
  const { children, ...rest } = props;
  return (
    <Typography align="left" sx={{ fontWeight: 600 }} {...rest}>
      {children}
    </Typography>
  );
}
export default Heading;
