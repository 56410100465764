import { Typography } from "@mui/material";

function Paragraph(props) {
  const { children, ...rest } = props;
  return (
    <Typography paragraph align="left" {...rest}>
      {children}
    </Typography>
  );
}

export default Paragraph;
