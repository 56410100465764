import { Box } from "@mui/system";
import PropTypes from "prop-types";

function Image(props) {
  const { noFloat, noRoundCorners } = props;
  return (
    <Box
      component="img"
      sx={{
        border: "1px solid #efefef",
        borderRadius: noRoundCorners
          ? {}
          : {
              xs: "0",
              md: "0.5rem",
            },
        boxShadow: "0.25rem 0.25rem 0.25rem 0px grey",
        p: {
          xs: 0,
          // md: "0.5rem",
        },
        display: {
          xs: "block",
          md: "block",
        },
        width: {
          xs: "100%",
          md: "70%",
        },
        ml: {
          xs: 0,
          md: noFloat ? 0 : "1rem",
        },
        mb: "1rem",
        maxWidth: { xs: "100%", md: "700px" },
        float: {
          md: noFloat ? "none" : "right",
        },
      }}
      {...props}
    />
  );
}

Image.propTypes = {
  /**
   * Injected by the documentation to work in an iframe.
   * You won't need it on your project.
   */
  src: PropTypes.string,
  alt: PropTypes.string,
  width: PropTypes.number,
  height: PropTypes.number,
  noFloat: PropTypes.bool,
  noRoundCorners: PropTypes.bool,
};

export default Image;
