import * as React from "react";
import PropTypes from "prop-types";
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import Divider from "@mui/material/Divider";
import Drawer from "@mui/material/Drawer";
import IconButton from "@mui/material/IconButton";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemText from "@mui/material/ListItemText";
import MenuIcon from "@mui/icons-material/Menu";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import { Container } from "@mui/system";
import { ListItemIcon, Paper } from "@mui/material";
import { Helmet } from "react-helmet";

import Home from "@mui/icons-material/Home";
import Celebration from "@mui/icons-material/Celebration";
import LocationOn from "@mui/icons-material/LocationOn";
import ContactPage from "@mui/icons-material/ContactPage";
import { Link } from "react-router-dom";

const drawerWidth = 240;

function DrawerAppBar(props) {
  const { window, children } = props;
  const [mobileOpen, setMobileOpen] = React.useState(false);
  const [, setTitle] = React.useState("");

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };

  const links = [
    ["Hem", "/", Home],
    // ["Klädsel", "/kladsel", AccessibilityNew],
    // ["Boende", "/boende", Hotel],
    ["Helgen", "/helgen", Celebration],
    ["Hitta Hit", "/hitta-hit", LocationOn],
    // ["OSA", "/osa", GroupAdd],
    ["Kontakt", "/kontakt", ContactPage],
  ];

  const drawer = (
    <Box
      className="navigation"
      onClick={handleDrawerToggle}
      sx={{ textAlign: "center" }}
    >
      <Typography variant="h6" sx={{ my: 2 }}>
        David & Astrid
      </Typography>
      <Divider />
      <List>
        {/* {navItems.map((item) => (
          <ListItem key={item} disablePadding>
            <ListItemButton sx={{ textAlign: "center" }}>
              <ListItemText primary={item} />
            </ListItemButton>
          </ListItem>
        ))} */}
        {/* {links.map(([text, url, Icon], index) => (
          <ListItem key={text} disablePadding>
            <ListItemButton
              component={Link}
              to={url}
              onClick={handleDrawerToggle}
            >
              <ListItemIcon>
                <Icon />
              </ListItemIcon>
              <ListItemText primary={text} />
            </ListItemButton>
          </ListItem>
        ))} */}
      </List>
    </Box>
  );

  const container =
    window !== undefined ? () => window().document.body : undefined;

  return (
    <Box>
      <Helmet onChangeClientState={(newState) => setTitle(newState.title)} />
      <AppBar className="navigation" component="nav">
        <Container maxWidth="lg">
          <Toolbar>
            <IconButton
              color="inherit"
              aria-label="open drawer"
              edge="start"
              onClick={handleDrawerToggle}
              sx={{ mr: 2, display: { sm: "none" } }}
            >
              <MenuIcon
                sx={{
                  mr: 2,
                }}
              />
              David & Astrid
            </IconButton>
            <Typography
              variant="h6"
              component="div"
              sx={{ flexGrow: 1, display: { xs: "none", sm: "block" } }}
            >
              <Button
                sx={{ color: "#fff", fontSize: "1.6rem" }}
                component={Link}
                to={"/"}
              >
                David & Astrid
              </Button>
            </Typography>
            <Box sx={{ display: { xs: "none", sm: "block" } }}>
              {/* {links.map(([text, url, Icon], index) => (
                <Button
                  key={index}
                  sx={{ color: "#fff" }}
                  component={Link}
                  to={url}
                >
                  <Icon
                    sx={{
                      mr: "0.3rem",
                    }}
                  />
                  {text}
                </Button>
              ))} */}
            </Box>
          </Toolbar>
        </Container>
      </AppBar>
      <Box component="nav">
        <Drawer
          container={container}
          variant="temporary"
          open={mobileOpen}
          onClose={handleDrawerToggle}
          ModalProps={{
            keepMounted: true, // Better open performance on mobile.
          }}
          sx={{
            display: { xs: "block", sm: "none" },
            "& .MuiDrawer-paper": {
              boxSizing: "border-box",
              width: drawerWidth,
            },
          }}
        >
          {drawer}
        </Drawer>
      </Box>
      <Box className="main" component="main" sx={{ p: 0 }}>
        <Toolbar />
        <Container
          maxWidth="lg"
          sx={{
            pl: {
              xs: 0,
              md: 2,
            },
            pr: {
              xs: 0,
              md: 2,
            },
          }}
        >
          <Paper
            sx={{
              p: 2,
              "& > h2:first-child, & > h1:first-child": {
                mt: 0,
              },
              mb: 5,
              pl: { xs: 1, md: 10 },
              pr: { xs: 1, md: 10 },
            }}
          >
            {children}
          </Paper>
        </Container>
      </Box>
    </Box>
  );
}

DrawerAppBar.propTypes = {
  /**
   * Injected by the documentation to work in an iframe.
   * You won't need it on your project.
   */
  window: PropTypes.func,
};

export default DrawerAppBar;
