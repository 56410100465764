import { H1, H2 } from "../../components/heading";
import Image from "../../components/image";
import Paragraph from "../../components/paragraph";
import DavidAstrid from "./david-astrid.jpg";

function Bilder() {
  return (
    <>
      <H1>David & Astrid</H1>
      <H2>Hej alla fantastiska bröllopsgäster</H2>
      <Paragraph>
        Vi vill återigen rikta världens största tack till er gäster som kom och
        firade vår helg med oss. Även om det har snart passerat tre månader nu
        så är vi alldeles pirriga när vi tänker tillbaka på den 27e maj 2023.
      </Paragraph>
      <Paragraph>
        Vi har med hjälp av vår fotograf satt upp ett onlinealbum där ni gäster
        och familj kan ta del av bilder från vår dag. Det är bara att höra av er
        om ni vill att något foto ska tas ner. Klicka på länken här nedanför och
        välj “Logga in som gäst”. Använd vilken mail ni vill som användarnamn,
        kommer inte att sparas, och det lösenord som vi skickade ut i smset.
      </Paragraph>
      <Paragraph>
        <a href="https://melodifoto.pixieset.com/astridanddavid/">
          Bröllopsbilder
        </a>
      </Paragraph>
      <Paragraph>
        All kärlek,
        <br />
        David och Astrid
      </Paragraph>
      <Image
        alt="Parkering"
        noFloat={true}
        noRoundCorners={true}
        src={DavidAstrid}
      />
      <div style={{ clear: "both" }} />
    </>
  );
}

export default Bilder;
