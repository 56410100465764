import { createTheme } from "@mui/material";

export const theme = createTheme({
  palette: {
    type: "light",
    primary: {
      main: "#52796F",
    },
    secondary: {
      main: "#75967d",
    },
  },
  typography: {
    h1: {
      marginTop: "1rem",
      marginBottom: "0.5rem",
      fontSize: "3rem",
      color: "#416159",
    },
    h2: {
      marginTop: "1rem",
      marginBottom: "0.5rem",
      fontSize: "2.5rem",
      color: "#628069",
    },
    h3: {
      marginTop: "1rem",
      marginBottom: "0.5rem",
      fontSize: "2rem",
      color: "#84A98C",
    },
  },
});
